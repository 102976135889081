import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import SEO from '../components/seo'
import ImageGallery from 'react-image-gallery'
import 'react-image-gallery/styles/css/image-gallery.css'

class Gallery extends React.Component {
  render() {
    const siteTitle = this.props.data.site.siteMetadata.title
    const siteDescription = this.props.data.site.siteMetadata.description
    const images = [
      {
        original:
          'https://res.cloudinary.com/dnla85pdq/image/upload/v1576215731/jjgates/gallery/2017-01-30-07.56.53-HDR-e1488840054441.jpg',
        thumbnail:
          'https://res.cloudinary.com/dnla85pdq/image/upload/c_thumb,w_200,g_face/v1576215731/jjgates/gallery/2017-01-30-07.56.53-HDR-e1488840054441.jpg',
      },
      {
        original:
          'https://res.cloudinary.com/dnla85pdq/image/upload/v1576215730/jjgates/gallery/2017-02-01-14.59.20.jpg',
        thumbnail:
          'https://res.cloudinary.com/dnla85pdq/image/upload/c_thumb,w_200,g_face/v1576215730/jjgates/gallery/2017-02-01-14.59.20.jpg',
      },
      {
        original:
          'https://res.cloudinary.com/dnla85pdq/image/upload/v1576215730/jjgates/gallery/20161111_151349.jpg',
        thumbnail:
          'https://res.cloudinary.com/dnla85pdq/image/upload/c_thumb,g_face,h_150,w_200/v1576215730/jjgates/gallery/20161111_151349.jpg',
      },
      {
        original:
          'https://res.cloudinary.com/dnla85pdq/image/upload/v1576215730/jjgates/gallery/20170112_143457.jpg',
        thumbnail:
          'https://res.cloudinary.com/dnla85pdq/image/upload/c_thumb,g_face,h_150,w_200/v1576215730/jjgates/gallery/20170112_143457.jpg',
      },
      {
        original:
          'https://res.cloudinary.com/dnla85pdq/image/upload/v1576215729/jjgates/gallery/Photo-Feb-12-1-48-15-PM-e1488839828506.jpg',
        thumbnail:
          'https://res.cloudinary.com/dnla85pdq/image/upload/c_thumb,g_face,h_150,w_200/v1576215729/jjgates/gallery/Photo-Feb-12-1-48-15-PM-e1488839828506.jpg',
      },
      {
        original:
          'https://res.cloudinary.com/dnla85pdq/image/upload/v1576215728/jjgates/gallery/2017-01-04-13.30.03-HDR.jpg',
        thumbnail:
          'https://res.cloudinary.com/dnla85pdq/image/upload/c_thumb,w_200,g_face/v1576215728/jjgates/gallery/2017-01-04-13.30.03-HDR.jpg',
      },
      {
        original:
          'https://res.cloudinary.com/dnla85pdq/image/upload/v1576215727/jjgates/gallery/2017-01-20-11.55.48-HDR.jpg',
        thumbnail:
          'https://res.cloudinary.com/dnla85pdq/image/upload/c_thumb,w_200,g_face/v1576215727/jjgates/gallery/2017-01-20-11.55.48-HDR.jpg',
      },
      {
        original:
          'https://res.cloudinary.com/dnla85pdq/image/upload/v1576215726/jjgates/gallery/2017-01-24-15.08.58-e1488840032205.jpg',
        thumbnail:
          'https://res.cloudinary.com/dnla85pdq/image/upload/c_thumb,h_150,w_200,g_face/v1576215726/jjgates/gallery/2017-01-24-15.08.58-e1488840032205.jpg',
      },
      {
        original:
          'https://res.cloudinary.com/dnla85pdq/image/upload/v1576215726/jjgates/gallery/2016-12-12-11.06.02-e1488840015708.jpg',
        thumbnail:
          'https://res.cloudinary.com/dnla85pdq/image/upload/c_thumb,h_150,w_200,g_face/v1576215726/jjgates/gallery/2016-12-12-11.06.02-e1488840015708.jpg',
      },
      {
        original:
          'https://res.cloudinary.com/dnla85pdq/image/upload/v1576215726/jjgates/gallery/2016-12-29-16.28.13-HDR.jpg',
        thumbnail:
          'https://res.cloudinary.com/dnla85pdq/image/upload/c_thumb,w_200,g_face/v1576215726/jjgates/gallery/2016-12-29-16.28.13-HDR.jpg',
      },
      {
        original:
          'https://res.cloudinary.com/dnla85pdq/image/upload/v1576215726/jjgates/gallery/2016-10-11-13.11.02-e1488839962207.jpg',
        thumbnail:
          'https://res.cloudinary.com/dnla85pdq/image/upload/c_thumb,h_150,w_200,g_face/v1576215726/jjgates/gallery/2016-10-11-13.11.02-e1488839962207.jpg',
      },
      {
        original:
          'https://res.cloudinary.com/dnla85pdq/image/upload/v1576215724/jjgates/gallery/2016-11-22-11.04.09-e1488839991627.jpg',
        thumbnail:
          'https://res.cloudinary.com/dnla85pdq/image/upload/c_thumb,h_150,w_200,g_face/v1576215724/jjgates/gallery/2016-11-22-11.04.09-e1488839991627.jpg',
      },
      {
        original:
          'https://res.cloudinary.com/dnla85pdq/image/upload/v1576215724/jjgates/gallery/2016-12-07-11.21.53.jpg',
        thumbnail:
          'https://res.cloudinary.com/dnla85pdq/image/upload/c_thumb,w_200,g_face/v1576215724/jjgates/gallery/2016-12-07-11.21.53.jpg',
      },
      {
        original:
          'https://res.cloudinary.com/dnla85pdq/image/upload/v1576215724/jjgates/gallery/2016-10-04-09.31.23.jpg',
        thumbnail:
          'https://res.cloudinary.com/dnla85pdq/image/upload/c_thumb,w_200,g_face/v1576215724/jjgates/gallery/2016-10-04-09.31.23.jpg',
      },
      {
        original:
          'https://res.cloudinary.com/dnla85pdq/image/upload/v1576215724/jjgates/gallery/2016-11-22-15.42.54-e1488840004962.jpg',
        thumbnail:
          'https://res.cloudinary.com/dnla85pdq/image/upload/c_thumb,h_150,w_200,g_face/v1576215724/jjgates/gallery/2016-11-22-15.42.54-e1488840004962.jpg',
      },
      {
        original:
          'https://res.cloudinary.com/dnla85pdq/image/upload/v1576215723/jjgates/gallery/2016-10-20-17.09.22.jpg',
        thumbnail:
          'https://res.cloudinary.com/dnla85pdq/image/upload/c_thumb,w_200,g_face/v1576215723/jjgates/gallery/2016-10-20-17.09.22.jpg',
      },
      {
        original:
          'https://res.cloudinary.com/dnla85pdq/image/upload/v1576215722/jjgates/gallery/2016-11-10-14.12.30.jpg',
        thumbnail:
          'https://res.cloudinary.com/dnla85pdq/image/upload/c_thumb,w_200,g_face/v1576215722/jjgates/gallery/2016-11-10-14.12.30.jpg',
      },
      {
        original:
          'https://res.cloudinary.com/dnla85pdq/image/upload/v1576215722/jjgates/gallery/2016-10-20-19.08.01-e1488839975335.jpg',
        thumbnail:
          'https://res.cloudinary.com/dnla85pdq/image/upload/c_thumb,h_150,w_200,g_face/v1576215722/jjgates/gallery/2016-10-20-19.08.01-e1488839975335.jpg',
      },
      {
        original:
          'https://res.cloudinary.com/dnla85pdq/image/upload/v1576215721/jjgates/gallery/2016-10-11-13.10.48.jpg',
        thumbnail:
          'https://res.cloudinary.com/dnla85pdq/image/upload/c_thumb,w_200,g_face/v1576215721/jjgates/gallery/2016-10-11-13.10.48.jpg',
      },
      {
        original:
          'https://res.cloudinary.com/dnla85pdq/image/upload/v1576215720/jjgates/gallery/2016-10-14-15.09.36.jpg',
        thumbnail:
          'https://res.cloudinary.com/dnla85pdq/image/upload/c_thumb,w_200,g_face/v1576215720/jjgates/gallery/2016-10-14-15.09.36.jpg',
      },
      {
        original:
          'https://res.cloudinary.com/dnla85pdq/image/upload/v1576215719/jjgates/gallery/2016-10-12-14.37.04.jpg',
        thumbnail:
          'https://res.cloudinary.com/dnla85pdq/image/upload/c_thumb,w_200,g_face/v1576215719/jjgates/gallery/2016-10-12-14.37.04.jpg',
      },
      {
        original:
          'https://res.cloudinary.com/dnla85pdq/image/upload/v1576215718/jjgates/gallery/2016-10-11-13.10.36.jpg',
        thumbnail:
          'https://res.cloudinary.com/dnla85pdq/image/upload/c_thumb,w_200,g_face/v1576215718/jjgates/gallery/2016-10-11-13.10.36.jpg',
      },
      {
        original:
          'https://res.cloudinary.com/dnla85pdq/image/upload/v1576215717/jjgates/gallery/2016-10-11-13.10.31.jpg',
        thumbnail:
          'https://res.cloudinary.com/dnla85pdq/image/upload/c_thumb,w_200,g_face/v1576215717/jjgates/gallery/2016-10-11-13.10.31.jpg',
      },
      {
        original:
          'https://res.cloudinary.com/dnla85pdq/image/upload/v1576215715/jjgates/gallery/2016-10-04-09.30.00-HDR.jpg',
        thumbnail:
          'https://res.cloudinary.com/dnla85pdq/image/upload/c_thumb,w_200,g_face/v1576215715/jjgates/gallery/2016-10-04-09.30.00-HDR.jpg',
      },
      {
        original:
          'https://res.cloudinary.com/dnla85pdq/image/upload/v1576215715/jjgates/gallery/2016-09-21-07.38.50.jpg',
        thumbnail:
          'https://res.cloudinary.com/dnla85pdq/image/upload/c_thumb,w_200,g_face/v1576215715/jjgates/gallery/2016-09-21-07.38.50.jpg',
      },
      {
        original:
          'https://res.cloudinary.com/dnla85pdq/image/upload/v1576215715/jjgates/gallery/2016-08-05-13.40.58-e1488839938351.jpg',
        thumbnail:
          'https://res.cloudinary.com/dnla85pdq/image/upload/c_thumb,h_150,w_200,g_face/v1576215715/jjgates/gallery/2016-08-05-13.40.58-e1488839938351.jpg',
      },
      {
        original:
          'https://res.cloudinary.com/dnla85pdq/image/upload/v1576215714/jjgates/gallery/2016-08-20-15.10.31.jpg',
        thumbnail:
          'https://res.cloudinary.com/dnla85pdq/image/upload/c_thumb,h_150,w_200,g_face/v1576215714/jjgates/gallery/2016-08-20-15.10.31.jpg',
      },
      {
        original:
          'https://res.cloudinary.com/dnla85pdq/image/upload/v1576215713/jjgates/gallery/2016-07-20-14.34.47-e1488839904629.jpg',
        thumbnail:
          'https://res.cloudinary.com/dnla85pdq/image/upload/c_thumb,h_150,w_200,g_face/v1576215713/jjgates/gallery/2016-07-20-14.34.47-e1488839904629.jpg',
      },
      {
        original:
          'https://res.cloudinary.com/dnla85pdq/image/upload/v1576215713/jjgates/gallery/2016-07-23-10.18.20-e1488839911378.jpg',
        thumbnail:
          'https://res.cloudinary.com/dnla85pdq/image/upload/c_thumb,h_150,w_200,g_face/v1576215713/jjgates/gallery/2016-07-23-10.18.20-e1488839911378.jpg',
      },
      {
        original:
          'https://res.cloudinary.com/dnla85pdq/image/upload/v1576215712/jjgates/gallery/2016-08-03-09.02.00.jpg',
        thumbnail:
          'https://res.cloudinary.com/dnla85pdq/image/upload/c_thumb,h_150,w_200,g_face/v1576215712/jjgates/gallery/2016-08-03-09.02.00.jpg',
      },
      {
        original:
          'https://res.cloudinary.com/dnla85pdq/image/upload/v1576215709/jjgates/gallery/2016-08-03-09.01.45-e1488839930434.jpg',
        thumbnail:
          'https://res.cloudinary.com/dnla85pdq/image/upload/c_thumb,h_150,w_200,g_face/v1576215709/jjgates/gallery/2016-08-03-09.01.45-e1488839930434.jpg',
      },
      {
        original:
          'https://res.cloudinary.com/dnla85pdq/image/upload/v1576215709/jjgates/gallery/2016-06-22-14.09.40.jpg',
        thumbnail:
          'https://res.cloudinary.com/dnla85pdq/image/upload/c_thumb,w_200,g_face/v1576215709/jjgates/gallery/2016-06-22-14.09.40.jpg',
      },
      {
        original:
          'https://res.cloudinary.com/dnla85pdq/image/upload/v1576215709/jjgates/gallery/2016-06-07-10.36.44-HDR.jpg',
        thumbnail:
          'https://res.cloudinary.com/dnla85pdq/image/upload/c_thumb,w_200,g_face/v1576215709/jjgates/gallery/2016-06-07-10.36.44-HDR.jpg',
      },
      {
        original:
          'https://res.cloudinary.com/dnla85pdq/image/upload/v1576215709/jjgates/gallery/2016-08-03-09.01.29-e1488839922809.jpg',
        thumbnail:
          'https://res.cloudinary.com/dnla85pdq/image/upload/c_thumb,h_150,w_200,g_face/v1576215709/jjgates/gallery/2016-08-03-09.01.29-e1488839922809.jpg',
      },
      {
        original:
          'https://res.cloudinary.com/dnla85pdq/image/upload/v1576215708/jjgates/gallery/2016-06-28-11.48.04-e1488839896921.jpg',
        thumbnail:
          'https://res.cloudinary.com/dnla85pdq/image/upload/c_thumb,h_150,w_200,g_face/v1576215708/jjgates/gallery/2016-06-28-11.48.04-e1488839896921.jpg',
      },
      {
        original:
          'https://res.cloudinary.com/dnla85pdq/image/upload/v1576215707/jjgates/gallery/2016-04-12-15.37.18.jpg',
        thumbnail:
          'https://res.cloudinary.com/dnla85pdq/image/upload/c_thumb,w_200,g_face/v1576215707/jjgates/gallery/2016-04-12-15.37.18.jpg',
      },
      {
        original:
          'https://res.cloudinary.com/dnla85pdq/image/upload/v1576215706/jjgates/gallery/2016-05-28-12.59.23.jpg',
        thumbnail:
          'https://res.cloudinary.com/dnla85pdq/image/upload/c_thumb,w_200,g_face/v1576215706/jjgates/gallery/2016-05-28-12.59.23.jpg',
      },
      {
        original:
          'https://res.cloudinary.com/dnla85pdq/image/upload/v1576215706/jjgates/gallery/2016-04-13-10.48.06-e1488839838974.jpg',
        thumbnail:
          'https://res.cloudinary.com/dnla85pdq/image/upload/c_thumb,h_150,w_200,g_face/v1576215706/jjgates/gallery/2016-04-13-10.48.06-e1488839838974.jpg',
      },
      {
        original:
          'https://res.cloudinary.com/dnla85pdq/image/upload/v1576215706/jjgates/gallery/2016-05-30-11.38.25-e1488840077741.jpg',
        thumbnail:
          'https://res.cloudinary.com/dnla85pdq/image/upload/c_thumb,w_200,g_face/v1576215706/jjgates/gallery/2016-05-30-11.38.25-e1488840077741.jpg',
      },
      {
        original:
          'https://res.cloudinary.com/dnla85pdq/image/upload/v1576215706/jjgates/gallery/2016-04-20-19.00.43-e1488839849237.jpg',
        thumbnail:
          'https://res.cloudinary.com/dnla85pdq/image/upload/c_thumb,h_150,w_200,g_face/v1576215706/jjgates/gallery/2016-04-20-19.00.43-e1488839849237.jpg',
      },
      {
        original:
          'https://res.cloudinary.com/dnla85pdq/image/upload/v1576215706/jjgates/gallery/2016-05-11-07.52.14-e1488839865693.jpg',
        thumbnail:
          'https://res.cloudinary.com/dnla85pdq/image/upload/c_thumb,h_150,w_200,g_face/v1576215706/jjgates/gallery/2016-05-11-07.52.14-e1488839865693.jpg',
      },
    ]

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO title="Gallery" description={siteDescription} />

        <div className="container-fluid text-center headline gates-headline">
          <div className="container">
            <h1 className="text-white m-0">Gallery</h1>
          </div>
        </div>

        <div className="container gates-container">
          <div className="row gates-row2">
            <div className="col-md-12">
              <ImageGallery items={images} />
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}

export default Gallery

export const GalleryQuery = graphql`
  query GalleryQuery {
    site {
      siteMetadata {
        title
        author
        description
      }
    }
  }
`
